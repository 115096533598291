// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../img/sublocation_add.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../img/delete.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../img/edit_logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".cursor[data-v-47e1875c] {\n  cursor: pointer;\n}\n.cursor .store[data-v-47e1875c] {\n  margin-top: 2px;\n}\n.store-add-logo[data-v-47e1875c] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  margin-left: 10px;\n  margin-right: 10px;\n  flex-grow: 0 !important;\n  width: 18px;\n  height: 18px;\n  background-size: contain;\n}\n.user-row[data-v-47e1875c]:not(:last-child) {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;\n}\n.user-form[data-v-47e1875c] {\n  width: 100%;\n}\n.user-form .form-border-bottom[data-v-47e1875c] {\n  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;\n}\n.user-form .label[data-v-47e1875c] {\n  font-family: Roboto;\n  font-size: 14px;\n  line-height: 16px;\n}\n.user-form input[data-v-47e1875c] {\n  width: 100%;\n}\n.user-form .required[data-v-47e1875c]:after {\n  color: #ff0000;\n  content: \" *\";\n  display: inline;\n}\n.row--delete[data-v-47e1875c] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: 20px;\n  cursor: pointer;\n  width: 20px;\n  height: 20px;\n  margin-top: -2px;\n}\n.row--edit[data-v-47e1875c] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-repeat: no-repeat;\n  background-size: 14px;\n  cursor: pointer;\n  margin-left: 15px;\n  width: 20px;\n  height: 20px;\n  padding: 5px;\n}\n.store[data-v-47e1875c] {\n  color: #93BD20;\n}", ""]);
// Exports
module.exports = exports;
