<template>
  <div>
    <context-title
      title="Create Users"
      :passedActions="primaryTitleBarActions"
    ></context-title>
    <v-container class="fill-height pa-3 pl-5" fluid>
      <form class="user-form">
        <v-row   class="form-border-bottom">
          <v-col cols="12" md="2" class="label align">Users</v-col>
          <v-col cols="12" md="6" class="store-container">
            <v-row
              align="center"
              
              v-for="user in users"
              :key="user.email"
              class="user-row"
            >
              <v-col cols="4">{{ user.name }}</v-col>
              <v-col cols="4">{{ user.email }}</v-col>
              <v-col cols="4">{{ user.phone }}</v-col>

              <div class="row--edit" @click.stop="onEditUser(user)"></div>
              <div class="row--delete" @click.stop="onDeleteUser(user)"></div>
            </v-row>
            <v-row
              v-if="!addingUser"
              align="center"
              class="cursor pl-3 pb-3 pt-3"
              @click.stop="
                () => {
                  addingUser = true;
                }
              "
            >
              <div sm11 class="store">Add Another User</div>
            </v-row>
          </v-col>
        </v-row>
        <template v-if="addingUser">
          <v-row  >
            <v-col cols="12" md="2" class="label required align">Name</v-col>
            <v-col cols="12" sm="6" md="3" class="form-input">
              <input type="text" v-model="userToAdd.name" />
            </v-col>
          </v-row>
          <v-row  >
            <v-col cols="12" md="2" class="label required align">Email</v-col>
            <v-col cols="12" sm="6" md="3" class="form-input">
              <input type="text" v-model="userToAdd.email" />
            </v-col>
          </v-row>
          <v-row  >
            <v-col cols="12" md="2" class="label align">Phone Number</v-col>
            <v-col cols="12" sm="6" md="3" class="form-input">
              <input type="text" v-model="userToAdd.phone" />
            </v-col>
          </v-row>
          <v-row
            align="center"
            class="cursor form-border-bottom pb-3 pt-3 pl-2"
            @click.stop="onAddUserDone"
          >
            <div sm11 class="store">Done</div>
          </v-row>
        </template>
        <v-row  >
          <v-col cols="12" md="2" class="label required align"
            >Temporary Partner Code</v-col
          >
          <v-col cols="12" sm="6" md="3" class="form-input">
            <input type="text" v-model="partnerShortCode" />
          </v-col>
        </v-row>
        <v-row   class="form-border-bottom">
          <v-col cols="12" md="2" class="label align">Delivery Partner?</v-col>
          <v-col cols="12" sm="6" md="3">
            <v-checkbox color="#93BD20" v-model="deliveryPartner"></v-checkbox>
          </v-col>
        </v-row>
        <v-row   class="form-border-bottom" v-if="!deliveryPartner">
          <v-col cols="12" md="2" class="label align">Stores</v-col>
          <v-col cols="12" md="6" class="store-container">
            <v-row
              align="center"
              
              v-for="store in storeAccess"
              :key="store.id"
            >
              <v-col cols="4" class="store">{{
                `${store.name} (${store.airportIdent})`
              }}</v-col>
              <div
                class="row--edit"
                @click.stop="
                  () => {
                    storeAccessToEdit = store;
                  }
                "
              ></div>
              <div class="row--delete" @click.stop="removeStore(store)"></div>
            </v-row>
            <v-row
              align="center"
              class="cursor pl-0 pb-3 pt-3"
              @click.stop="
                () => {
                  addStoreModal = true;
                }
              "
            >
              <div sm1 class="store-add-logo" />
              <div sm11 class="store">Add Store</div>
            </v-row>
          </v-col>
        </v-row>
      </form>
    </v-container>
    <EditingModal v-if="navigationConfirmationModal" size="sm">
      <AlertDialog
        title="Unsaved Changes"
        :onExit="() => (navigationConfirmationModal = false)"
        :actions="navigationConfirmationActions"
        >You have unsaved changes, would you like to save these
        changes?</AlertDialog
      >
    </EditingModal>
    <EditStoreModal
      v-if="storeAccessToEdit"
      :store="storeAccessToEdit"
      @close="onEditStoreClose"
      @save="onEditStoreSave"
    />
    <AddStoresModal
      v-if="addStoreModal"
      @close="onAddStoreClose"
      @save="onAddStoreSave"
    />
  </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import ActionButton from "components/action_button";
import TextButton from "components/text_button.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import { mapGetters, mapActions } from "vuex";
import clone from "clone";

import EditStoreModal from "components/users/edit_store_modal";
import AddStoresModal from "components/users/add_stores_modal";

let emptyUser = {
    name: "",
    email: "",
    phone: "",
};
export default {
    name: "UserCreate",
    data() {
        return {
            pendingRoute: undefined,
            navigationConfirmationModal: false,
            navigationConfirmationActions: [
                {
                    type: "remove-red",
                    display: "No",
                    run: this.confirmNavigation,
                },
                {
                    type: "proceed-green",
                    display: "Yes",
                    run: () => {
                        this.navigationConfirmationModal = false
                    },
                },
            ],
            leaveRoute: "users",
            userCopy: false,
            storeAccessToEdit: undefined,
            addStoreModal: false,
            users: [],
            storeAccess: [],
            addingUser: false,
            userToAdd: undefined,
            partnerShortCode: "",
            deliveryPartner: false,
        };
    },
    components: {
        ContextTitle,
        ActionButton,
        TextButton,
        AlertDialog,
        EditingModal,
        EditStoreModal,
        AddStoresModal,
    },
    computed: {
        primaryTitleBarActions() {
            const close = {
                type: "faded",
                display: "Close",
                run: () => {
                    this.leave();
                },
            };

            if (!this.dirty || !this.valid) return [close];
            const saveClose = {
                type: "proceed-green",
                display: "Save & Close",
                run: () => {
                    this.save().then(() => this.leave());
                },
            };
            return [close, saveClose];
        },
        dirty() {
            return this.users.length > 0 || this.storeAccess.length > 0;
        },
        valid() {
            return (
                this.users.length > 0 &&
        (this.storeAccess.length > 0 || this.deliveryPartner) &&
        this.partnerShortCode.length > 0
            );
        },
        ...mapGetters("UserStore", ["duplicatedStoreAccess"]),
    },
    methods: {
        leave() {
            this.guardRouteAway({
                name: this.leaveRoute,
            });
        },
        guardRouteAway(route) {
            if (!this.dirty) {
                this.$router.push(route);
                return;
            }

            this.pendingRoute = route;
            this.navigationConfirmationModal = true;
        },
        confirmNavigation() {
            this.navigationConfirmationModal = false;
            this.$router.push(this.pendingRoute);
            this.pendingRoute = undefined;
        },
        save() {
            return this.createUsers(
                this.users.map((el) => {
                    return {
                        ...el,
                        partnerShortCode: this.partnerShortCode,
                        storeAccess: this.storeAccess,
                        deliveryPartner: this.deliveryPartner,
                    };
                })
            ).then(() => {
                this.users = [];
                this.storeAccess = [];
                return Promise.resolve(true);
            });
        },
        init() {
            this.userToAdd = clone(emptyUser);

            if (this.duplicatedStoreAccess != undefined) {
                this.storeAccess = clone(this.duplicatedStoreAccess).map((el) => {
                    return {
                        ...el,
                        airportAbbr: el.airportIdent,
                        name: el.storeName,
                    };
                });
                this.clearDuplicatedStoreAccess();
            }
        },
        onEditStoreClose() {
            this.storeAccessToEdit = undefined;
        },
        onEditStoreSave(newStore) {
            this.storeAccessToEdit = undefined;
            let index = this.storeAccess.findIndex(
                (el) => el.storeWaypointID == newStore.storeWaypointID
            );
            if (index == -1) return;
            this.storeAccess.splice(index, 1);
            this.storeAccess.push(newStore);
        },
        onAddStoreClose() {
            this.addStoreModal = false;
        },
        onAddStoreSave({ stores, permissions }) {
            this.addStoreModal = false;
            for (let store of stores) {
                this.storeAccess.push({
                    ...store,
                    permissions,
                });
            }
        },
        onAddUserDone() {
            this.users.push(this.userToAdd);
            this.userToAdd = clone(emptyUser);
            this.addingUser = false;
        },
        onEditUser(user) {
            let index = this.users.findIndex((el) => el.email == user.email);
            if (index == -1) return;
            this.users.splice(index, 1);
            this.userToAdd = user;
            this.addingUser = true;
        },
        onDeleteUser(user) {
            let index = this.users.findIndex((el) => el.email == user.email);
            if (index == -1) return;
            this.users.splice(index, 1);
        },
        removeStore(store) {
            let index = this.userCopy.storeAccess.findIndex(
                (el) => el.storeWaypointID === store.storeWaypointID
            );

            if (index == -1) return;

            var access = clone(this.userCopy.storeAccess);
            access.splice(index, 1);
            this.userCopy.storeAccess = access;
        },
        ...mapActions("UserStore", ["createUsers", "clearDuplicatedStoreAccess"]),
    },
    mounted() {
        this.init();
    },
    watch: {},
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";
@import "~scss/variables";

.cursor {
  cursor: pointer;
}

.cursor .store {
  margin-top: 2px;
}
.store-add-logo {
  @include background-image("", "sublocation_add.svg", "../../../img/");
  margin-left: 10px;
  margin-right: 10px;
  flex-grow: 0 !important;
  width: spacing(sm);
  height: spacing(sm);
  background-size: contain;
}
.user-row:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
}
.user-form {
  width: 100%;

  .form-border-bottom {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15) !important;
  }
  .label {
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
  }
  input {
    width: 100%;
  }

  .required:after {
    color: #ff0000;
    content: " *";
    display: inline;
  }
}

.row {
  &--delete {
    @include background-image("", "delete.svg", "../../../img/");
    background-size: 20px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-top: -2px;
  }
  &--edit {
    @include background-image("", "edit_logo.svg", "../../../img/");
    background-size: 14px;
    cursor: pointer;

    margin-left: 15px;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}

.store {
  color: $brand_color;
}
</style>
