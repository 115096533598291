var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("context-title", {
        attrs: {
          title: "Create Users",
          passedActions: _vm.primaryTitleBarActions,
        },
      }),
      _c(
        "v-container",
        { staticClass: "fill-height pa-3 pl-5", attrs: { fluid: "" } },
        [
          _c(
            "form",
            { staticClass: "user-form" },
            [
              _c(
                "v-row",
                { staticClass: "form-border-bottom" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "label align",
                      attrs: { cols: "12", md: "2" },
                    },
                    [_vm._v("Users")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "store-container",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _vm._l(_vm.users, function (user) {
                        return _c(
                          "v-row",
                          {
                            key: user.email,
                            staticClass: "user-row",
                            attrs: { align: "center" },
                          },
                          [
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _vm._v(_vm._s(user.name)),
                            ]),
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _vm._v(_vm._s(user.email)),
                            ]),
                            _c("v-col", { attrs: { cols: "4" } }, [
                              _vm._v(_vm._s(user.phone)),
                            ]),
                            _c("div", {
                              staticClass: "row--edit",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onEditUser(user)
                                },
                              },
                            }),
                            _c("div", {
                              staticClass: "row--delete",
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.onDeleteUser(user)
                                },
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      !_vm.addingUser
                        ? _c(
                            "v-row",
                            {
                              staticClass: "cursor pl-3 pb-3 pt-3",
                              attrs: { align: "center" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (() => {
                                    _vm.addingUser = true
                                  }).apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "store", attrs: { sm11: "" } },
                                [_vm._v("Add Another User")]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.addingUser
                ? [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "label required align",
                            attrs: { cols: "12", md: "2" },
                          },
                          [_vm._v("Name")]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "form-input",
                            attrs: { cols: "12", sm: "6", md: "3" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userToAdd.name,
                                  expression: "userToAdd.name",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.userToAdd.name },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.userToAdd,
                                    "name",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "label required align",
                            attrs: { cols: "12", md: "2" },
                          },
                          [_vm._v("Email")]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "form-input",
                            attrs: { cols: "12", sm: "6", md: "3" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userToAdd.email,
                                  expression: "userToAdd.email",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.userToAdd.email },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.userToAdd,
                                    "email",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "label align",
                            attrs: { cols: "12", md: "2" },
                          },
                          [_vm._v("Phone Number")]
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "form-input",
                            attrs: { cols: "12", sm: "6", md: "3" },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userToAdd.phone,
                                  expression: "userToAdd.phone",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: _vm.userToAdd.phone },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.userToAdd,
                                    "phone",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      {
                        staticClass: "cursor form-border-bottom pb-3 pt-3 pl-2",
                        attrs: { align: "center" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.onAddUserDone.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "store", attrs: { sm11: "" } },
                          [_vm._v("Done")]
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "label required align",
                      attrs: { cols: "12", md: "2" },
                    },
                    [_vm._v("Temporary Partner Code")]
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "form-input",
                      attrs: { cols: "12", sm: "6", md: "3" },
                    },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.partnerShortCode,
                            expression: "partnerShortCode",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.partnerShortCode },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.partnerShortCode = $event.target.value
                          },
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "form-border-bottom" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "label align",
                      attrs: { cols: "12", md: "2" },
                    },
                    [_vm._v("Delivery Partner?")]
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", sm: "6", md: "3" } },
                    [
                      _c("v-checkbox", {
                        attrs: { color: "#93BD20" },
                        model: {
                          value: _vm.deliveryPartner,
                          callback: function ($$v) {
                            _vm.deliveryPartner = $$v
                          },
                          expression: "deliveryPartner",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              !_vm.deliveryPartner
                ? _c(
                    "v-row",
                    { staticClass: "form-border-bottom" },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "label align",
                          attrs: { cols: "12", md: "2" },
                        },
                        [_vm._v("Stores")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "store-container",
                          attrs: { cols: "12", md: "6" },
                        },
                        [
                          _vm._l(_vm.storeAccess, function (store) {
                            return _c(
                              "v-row",
                              { key: store.id, attrs: { align: "center" } },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "store",
                                    attrs: { cols: "4" },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        `${store.name} (${store.airportIdent})`
                                      )
                                    ),
                                  ]
                                ),
                                _c("div", {
                                  staticClass: "row--edit",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return (() => {
                                        _vm.storeAccessToEdit = store
                                      }).apply(null, arguments)
                                    },
                                  },
                                }),
                                _c("div", {
                                  staticClass: "row--delete",
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.removeStore(store)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          }),
                          _c(
                            "v-row",
                            {
                              staticClass: "cursor pl-0 pb-3 pt-3",
                              attrs: { align: "center" },
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return (() => {
                                    _vm.addStoreModal = true
                                  }).apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("div", {
                                staticClass: "store-add-logo",
                                attrs: { sm1: "" },
                              }),
                              _c(
                                "div",
                                { staticClass: "store", attrs: { sm11: "" } },
                                [_vm._v("Add Store")]
                              ),
                            ]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm.navigationConfirmationModal
        ? _c(
            "EditingModal",
            { attrs: { size: "sm" } },
            [
              _c(
                "AlertDialog",
                {
                  attrs: {
                    title: "Unsaved Changes",
                    onExit: () => (_vm.navigationConfirmationModal = false),
                    actions: _vm.navigationConfirmationActions,
                  },
                },
                [
                  _vm._v(
                    "You have unsaved changes, would you like to save these changes?"
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.storeAccessToEdit
        ? _c("EditStoreModal", {
            attrs: { store: _vm.storeAccessToEdit },
            on: { close: _vm.onEditStoreClose, save: _vm.onEditStoreSave },
          })
        : _vm._e(),
      _vm.addStoreModal
        ? _c("AddStoresModal", {
            on: { close: _vm.onAddStoreClose, save: _vm.onAddStoreSave },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }